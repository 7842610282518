<template>
  <section
    id="card-images"
    class="vodSection"
  >
    <b-row>
      <b-col
        v-for="item in downloads"
        :key="item._id"
        md="5"
        lg="4"
        xl="3"
      ><router-link
                :to="`/videos/${item._id}`">
        <b-card class="mb-3">
          <div class="vod__image">
            <img
              width="100%"
              :src="item.vodThumbnailUrl.replace('https://content.brimecdn.com/brime-vods/', 'https://vod.brimecdn.com/n/idmrpurnoj34/b/brime-vods/o/').replace('content.brimecdn.com', 'hls.brimecdn.com')"
            >
            <div class="vod__duration">
              {{ getDuration(item.startDate, item.endDate) }}
            </div>
          </div>
          <div
            id="vodTitle"
            class="vod__title"
          >
            {{ item.stream.title }}
          </div><span class="float-right"><b-button variant="primary" size="sm" download="filename.mp4" :href="`${item.downloadLink}`" target="none"><feather-icon
                  icon="DownloadIcon"
                  class="mr-50 pt-0 pb-0"
                />Download</b-button></span>
          <div class="vod__category">
            {{ item.stream.category.name }}
          </div>
          <div class="vod__date">
            {{ getAgo(item.startDate) }}
          </div>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
        </b-card></router-link></b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCard,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default ({
  components: {
    BRow,
    BCard,
    BCol,
    BButton,
  },
  data() {
    return {
      userInfo: {},
      downloads: [],
    }
  },
  async mounted() {
    this.userInfo = await window.brime.getUser()
    this.vods = await window.brime.getVodsForChannel(this.userInfo.channels[0])
    this.downloads = this.vods.vods.filter(e => e.downloadReady === true)
  },
  methods: {
    // Dates and Times
    getDate(clipEpoch) {
      const d = new Date(clipEpoch)
      return d.toLocaleString()
    },
    getDuration(start, end) {
      if (end === -1) return 'LIVE'
      return new Date(end - start).toISOString().substr(11, 8)
    },
    getAgo(start) {
      let ago = Math.floor((new Date().getTime() - start) / 1000 / 60)
      if (ago < 60) {
        return `${ago}min ago`
      }
      ago = Math.floor(ago / 60)
      if (ago < 24) {
        return `${ago}h ago`
      }
      return `${Math.floor(ago / 24)} Days ago`
    },
    getDurationClip(start, end) {
      if (end === -1) return 'live'
      const timeStamp = new Date(end * 1000 - start * 1000)
        .toISOString()
        .substr(11, 8)
      return timeStamp.substr(3)
    },
    getAgoClip(start) {
      const secondsSinceEpoch = Math.floor(new Date().getTime())
      let ago = Math.floor((secondsSinceEpoch / 1000 - start) / 60)
      if (ago < 60) {
        return `${ago}min ago`
      }
      ago = Math.floor(ago / 60)
      if (ago < 24) {
        return `${ago}h ago`
      }
      return `${Math.floor(ago / 24)} Days ago`
    },
  },
})
</script>

<style>
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}
.chatBadge:not(:first-child) {
  padding-left: 2px;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -20px;
  margin-left: 55px;
  font-size: 14px;
}

.chat-thread {
  padding-left: 5px;
  padding-top: 7px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 1px 5px 1px 10px;
  margin: 0 0 10px 0;
  font: 16px/20px;
  width: 100%;
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:before {
  position: absolute;
  top: 5px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: left;
  margin-left: 0px;
  color: #fff;
  word-wrap: break-word;
}

.chat-thread li:after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-window {
  position: fixed;
  bottom: 12px;
  right: 0;
  width: 100%;
}
.chatInput {
  margin-left: 2.5%;
  margin-right: 2.5%;
  width: 96%;
  bottom: 0;
  box-shadow: none !important;
}

.chat-window-message {
  width: 100%;
  height: 48px;
  font: 32px/48px "Noto Sans", sans-serif;
  background: none;
  color: #ffff;
  border: 0;
  border-bottom: 1px solid rgba(25, 147, 147, 0.2);
  outline: none;
  bottom: 0;
  display: fixed;
}

/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
  .chat-thread {
    width: 90%;
    height: 230px;
  }
  .chat-window {
    left: 5%;
    width: 90%;
  }
  #stream-video {
    min-height: unset !important;
    height: unset !important;
  }
  .column1 {
    width: 80%;
    margin-left: -15px;
    height: unset !important;
    margin-top: -10px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .tabSection {
    display: none;
  }
  .actionButtons {
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 2px !important;
    margin-left: 0 !important;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  .chat-thread {
    width: 100%;
    height: 85vh;
  }
  #stream-video {
    /* override other styles to make responsive */
    width: 100% !important;
    min-height: 84vh !important;
  }
  .chat-window {
    width: 100%;
  }
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vod__category,
.vod__date {
  font-size: 0.9em;
  color: #fff;
}

.tabSection {
  margin-top: 8vh;
  margin-left: 1vw;
  margin-right: 1vw;
}
.vodSection {
  margin-top: 5vh;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.btn {
  margin-left: 10px !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-left: 15px;
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
button {
  margin-left: 20px;
}
.column1 {
  width: 80%;
  margin-left: -15px;
  height: 80vh;
  margin-top: -5px;
}
.column {
  width: calc(20% + 0.5rem);
  flex: 1;
  background: #111727;
  margin-right: -12px;
  padding-bottom: 2vh;
  height: 100%;
}
.row {
  margin-top: -25px !important;
}
.channel__row {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

@media screen and (min-width: 769px) {
  .chat__column {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
    padding-bottom: 1rem;
  }

  .chat-thread {
    flex: 1;
  }

  #chat-form {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 768px) {
  .column1 {
    width: 100%;
    margin-left: 0px;
    margin: 0;
    padding: 0;
  }

  .channel__row {
    flex-wrap: wrap;
  }

  .chat__column {
    width: 100%;
    flex-shrink: 0;
    margin: 0;
  }

  .chat__column form {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .chat-thread {
    width: 100%;
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
</style>
